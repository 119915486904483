import React, { useCallback } from "react"

import useTrackContext from "decentraland-gatsby/dist/context/Track/useTrackContext"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import { ContentfulCreatorsConnectEntryProps } from "../../../hooks/useLandingContentfulQuery"
import { SegmentEvent } from "../../../modules/segment"

import "./CreatorsConnectCard.css"

type CreatorsConnectCardProps = {
  item: ContentfulCreatorsConnectEntryProps
  className?: string
}

export const CreatorsConnectCard = (props: CreatorsConnectCardProps) => {
  const { item, className } = props

  const track = useTrackContext()

  const handleCardClick = useCallback(() => {
    if (item.url) {
      window.open(item.url, "_blank")
      track(SegmentEvent.CreatorsConnectCard, item)
    }
  }, [item.url, track])

  return (
    <div
      key={item.id}
      className={TokenList.join([
        `creators-connect-card`,
        className,
        item.url && "creators-connect-card--clickable",
      ])}
      onClick={handleCardClick}
    >
      <div className="creators-connect-card__description">
        "{item.description}"
      </div>
      <div className="creators-connect-card__user">
        <div className="creators-connect-card__user-image">
          <img src={item.image.url} alt={item.name} />
        </div>
        <div className="creators-connect-card__user-name">{item.name}</div>
      </div>
    </div>
  )
}
