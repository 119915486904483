import React, { useState } from "react"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import SubTitle from "decentraland-gatsby/dist/components/Text/SubTitle"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import { ContentfulFaqEntriesProps } from "../../../hooks/useLandingContentfulQuery"
import { SectionViewedTrack } from "../../../modules/segment"
import FaqList from "./Faqs"

import "./LandingFaqs.css"

export type LandingFAQSAttributes = {
  subTitle: string
  title: string
  questions: any[]
  cta: {
    entry: string
    link: string
  }
}

export type LandingFAQSProps = {
  faqs: ContentfulFaqEntriesProps
  sectionViewed: SectionViewedTrack
  className?: string
}

export default React.memo(function LandingFaqs(props: LandingFAQSProps) {
  const l = useFormatMessage()
  const [expanded, setExpanded] = useState(false)
  return (
    <Container fluid className={TokenList.join(["faqs", props.className])}>
      <SectionTrack sectionViewed={props.sectionViewed} />
      <div>
        <div className="faqs__container">
          <SubTitle>{l("component.landing.faqs.subtitle")}</SubTitle>
          <Title>{l("component.landing.faqs.title")}</Title>
          <FaqList faqs={props.faqs} />
          <Button
            className="faqs__cta button-faqs"
            size="large"
            href={l("component.landing.faqs.cta.link")}
            inverted
          >
            {l("component.landing.faqs.cta.label")}
          </Button>
        </div>
      </div>
    </Container>
  )
})
