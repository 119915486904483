import React, { useCallback, useState } from "react"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import { ContentfulCreatorsConnectEntriesProps } from "../../../hooks/useLandingContentfulQuery"
import discordImage from "../../../images/discord.svg"
import { SectionViewedTrack } from "../../../modules/segment"
import { Carousel, IndicatorType } from "../../Carousel/Carousel"
import { CreatorsConnectCard } from "./CreatorsConnectCard"

import "./CreatorsConnect.css"

export type CreatorsConnectProps = {
  items: ContentfulCreatorsConnectEntriesProps
}

export const CreatorsConnect = (props: CreatorsConnectProps) => {
  const l = useFormatMessage()
  const { items } = props

  const carouselRef = React.useRef<any>(null)
  const [isSectionInView, setIsSectionInView] = useState(false)

  const handleDiscordClick = useCallback(
    () => window.open(l("general.discord_cta_target"), "_blank"),
    []
  )

  return (
    <div
      className={TokenList.join([
        "creators-connect",
        isSectionInView && "creators-connect--visible",
      ])}
    >
      <SectionTrack
        sectionViewed={SectionViewedTrack.CreatorsConnect}
        onInView={() => setIsSectionInView(true)}
      />
      <Title>
        <span>{l("component.creators_landing.connect.title_hightlight")}</span>{" "}
        {l("component.creators_landing.connect.title")}
      </Title>
      <Carousel
        loading={false}
        ref={carouselRef}
        items={items.list || []}
        component={CreatorsConnectCard}
        slidesPerView="auto"
        className="creators-connect__carousel"
        forceToAxis
        spaceBetween={16}
        pagination
        loop
        indicatorsType={IndicatorType.Dash}
      />
      <div
        className="creators-connect__discord-container"
        onClick={handleDiscordClick}
      >
        <div className="creators-connect__discord-title">
          {l("component.creators_landing.connect.join_the_community")}
        </div>
        <div className="creators-connect__discord-icon">
          <img src={discordImage} alt="Discord" />
        </div>
      </div>
    </div>
  )
}
