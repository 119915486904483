import React, { useState } from "react"

import SubTitle from "decentraland-gatsby/dist/components/Text/SubTitle"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Tabs } from "decentraland-ui/dist/components/Tabs/Tabs"

import {
  isWebpSupported,
  useImageOptimization,
} from "../../../hooks/contentful"
import { ContentfulCreatorsCreateEntryProps } from "../../../hooks/useLandingContentfulQuery"
import { Chevron } from "../../Icon/Chevron"
import { CreatorsCreateTab } from "./CreatorsCreateTab"

import "./CreatorsCreateCard.css"

export type CreatorsCreateCardProps = {
  item: ContentfulCreatorsCreateEntryProps
}

export const CreatorsCreateCard = (props: CreatorsCreateCardProps) => {
  const { item } = props

  const [activeTab, setActiveTab] = useState(item.tab1Title)

  const image = useImageOptimization(item.image.url)
  const imageBackground = useImageOptimization(item.imageBackground.url)

  return (
    <div
      className={TokenList.join(["create-card", `create-card__${item.id}`])}
      key={item.id}
    >
      <div
        className="create-card__image-container"
        style={{
          backgroundImage: `url(${
            (isWebpSupported() && imageBackground.webp) ||
            imageBackground.jpg ||
            imageBackground.optimized
          })`,
        }}
      >
        <img
          src={
            (isWebpSupported() && image.webp) || image.png || image.optimized
          }
        />
      </div>
      <div className="create-card__information-container">
        <div className="create-card__text">
          <Title>{item.title}</Title>
          <SubTitle>{item.description}</SubTitle>
        </div>
        <div className="create-tabs-container">
          {item.tab2Title && (
            <Tabs>
              <Tabs.Tab
                onClick={() => setActiveTab(item.tab1Title)}
                active={activeTab === item.tab1Title}
              >
                {item.tab1Title}
              </Tabs.Tab>

              <Tabs.Tab
                onClick={() => setActiveTab(item.tab2Title)}
                active={activeTab === item.tab2Title}
              >
                {item.tab2Title}
              </Tabs.Tab>
            </Tabs>
          )}
          {activeTab === item.tab1Title && (
            <CreatorsCreateTab
              key={item.tab1DescriptionTitle}
              title={item.tab1DescriptionTitle}
              subtitle={item.tab1DescriptionSubTitle}
              skills={item.tab1Skills}
              links={JSON.parse(item.tab1Links.raw)}
            />
          )}
          {activeTab === item.tab2Title && (
            <CreatorsCreateTab
              key={item.tab2DescriptionTitle}
              title={item.tab2DescriptionTitle}
              subtitle={item.tab2DescriptionSubTitle}
              skills={item.tab2Skills}
              links={JSON.parse(item.tab2Links.raw)}
            />
          )}
        </div>
      </div>
    </div>
  )
}
