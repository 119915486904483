import React, { useCallback } from "react"

import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import { ContentfulCreatorsLearnEntryProps } from "../../../hooks/useLandingContentfulQuery"
import { Play } from "../../Icon/Play"

import "./CreatorsLearnCard.css"

type CreatorsLearnCardProps = {
  item: ContentfulCreatorsLearnEntryProps
  className?: string
}

export const CreatorsLearnCard = (props: CreatorsLearnCardProps) => {
  const { item, className } = props

  const handleClick = useCallback(() => {
    window.open(item.url, "_blank")
  }, [item.url])

  return (
    <div
      key={item.id}
      className={TokenList.join([`creators-learn-card`, className])}
      onClick={handleClick}
    >
      <div className="creators-learn-card__video-image">
        <img src={item.image.url} alt={item.name} />
        <Play />
      </div>
      <div className="creators-learn-card__information-container">
        <div>
          <div className="creators-learn-card__user">
            <div className="creators-learn-card__user-image">
              <img src={item.userImage.url} alt={item.name} />
            </div>
            <div className="creators-learn-card__user-name">{item.name}</div>
          </div>
          <div className="creators-learn-card__date">{item.date}</div>
        </div>

        <div className="creators-learn-card__title">{item.title}</div>
      </div>
    </div>
  )
}
