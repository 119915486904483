import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react"

import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import {
  Autoplay,
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Swiper as SwiperClass,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper-bundle.css"
import "./Carousel.css"

export enum IndicatorType {
  Bullet = "bullet",
  Dash = "dash",
}

export type CarouselProps<T = any> = React.HTMLProps<HTMLDivElement> & {
  items: T[]
  component: React.ComponentType<{ item: T }>
  slidesPerView?: number | "auto"
  spaceBetween?: number
  loop?: boolean | true
  centeredSlides?: boolean
  loading: boolean
  onIndexChange?: (index: number) => void
  freeMode?: boolean
  navigation?: boolean
  forceToAxis?: boolean
  centerInsufficientSlides?: boolean
  pagination?: boolean
  indicatorsType?: IndicatorType
  breakpoints?: Record<number, Record<"slidesPerView" | "spaceBetween", number>>
  autoplay?: {
    delay?: number
    disableOnInteraction?: boolean
    pauseOnMouseEnter?: boolean
  }
}

export const Carousel = forwardRef<any, CarouselProps>(
  (props: CarouselProps, ref: ForwardedRef<any>) => {
    const {
      items,
      slidesPerView,
      spaceBetween,
      centeredSlides,
      loop,
      loading,
      onIndexChange,
      freeMode,
      navigation,
      forceToAxis,
      centerInsufficientSlides,
      className,
      breakpoints,
      indicatorsType,
      pagination,
      autoplay,
    } = props
    const swiperRef = useRef<SwiperClass>()

    const [activeIndex, setActiveIndex] = React.useState(0)

    useImperativeHandle(ref, () => ({
      next() {
        swiperRef.current?.slideNext()
      },
      previous() {
        swiperRef.current?.slidePrev()
      },
    }))

    if (loading || items.length === 0) {
      return <div>Loading...</div>
    }

    const handleOnIndexChange = (swiper: SwiperClass) => {
      onIndexChange && onIndexChange(swiper.activeIndex)
      setActiveIndex(swiper.activeIndex)
    }

    return (
      <div
        className={TokenList.join([
          "carousel-landing ",
          className,
          indicatorsType === IndicatorType.Dash && "dash-indicators",
        ])}
        data-index={activeIndex}
      >
        <Swiper
          onActiveIndexChange={handleOnIndexChange}
          loop={loop}
          modules={[Autoplay, Navigation, FreeMode, Mousewheel, Pagination]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper
          }}
          navigation={navigation}
          slidesPerView={slidesPerView || "auto"}
          centeredSlides={centeredSlides}
          spaceBetween={spaceBetween || 20}
          freeMode={freeMode && { enabled: true }}
          mousewheel={forceToAxis && { forceToAxis: true }}
          breakpoints={breakpoints}
          centerInsufficientSlides={centerInsufficientSlides}
          pagination={
            pagination && {
              clickable: true,
              dynamicBullets: items.length > 5 && true,
              dynamicMainBullets: 3,
            }
          }
          autoplay={autoplay}
        >
          {items.map((item, i) => (
            <SwiperSlide key={i}>
              <props.component item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  }
)
