import React from "react"

import {
  Options,
  documentToReactComponents,
} from "@contentful/rich-text-react-renderer"
import { Document, INLINES, Text } from "@contentful/rich-text-types"
import SubTitle from "decentraland-gatsby/dist/components/Text/SubTitle"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import "./CreatorsCreateTab.css"

export type CreatorsCreateTabProps = {
  title: string
  subtitle: string
  skills: string[]
  links: Document
}

export const richTextOptions: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node) => {
      const content = node.content as [Text]
      return (
        <a href={node.data.uri} target="_blank" className="create-tab__link">
          {content[0].value}
        </a>
      )
    },
  },
  renderText: () => null,
}

export const CreatorsCreateTab = (props: CreatorsCreateTabProps) => {
  const { title, subtitle, skills, links } = props

  const l = useFormatMessage()

  return (
    <div className="create-tab" key={title}>
      <div className="create-tab__information-container">
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </div>
      <div className="create-tab__information-container">
        <Title>
          {l("component.creators_landing.create.tab.required_skills")}
        </Title>
        <div className="create-tab__skills-container">
          {skills.map((skill: string) => (
            <div
              key={skill}
              className={TokenList.join([
                "create-tab__skill",
                `icon-${skill.replaceAll(" ", "-").toLowerCase()}`,
              ])}
            >
              {skill}
            </div>
          ))}
        </div>
      </div>
      <div
        className={TokenList.join([
          "create-tab__information-container",
          "create-tab__links",
        ])}
      >
        <Title>{l("component.creators_landing.create.tab.useful_links")}</Title>
        <div className="create-tab__links-container">
          {documentToReactComponents(links, richTextOptions)}
        </div>
      </div>
    </div>
  )
}
