import React from "react"

import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import "./Chevron.css"

export type ChevronProps = React.SVGAttributes<SVGElement> & {
  dark?: boolean
  direction?: "down" | "up" | "right" | "left"
}

export const Chevron = React.memo(function (props: ChevronProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      className={TokenList.join([
        "icon-chevron",
        props.className,
        props.dark && "dark",
        props.direction,
      ])}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.585815 3.41424L3.41424 0.585815L10.5 7.6716L17.5858 0.585815L20.4142 3.41424L10.5 13.3285L0.585815 3.41424Z"
      />
    </svg>
  )
})
