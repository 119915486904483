import React from "react"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import { ContentfulCreatorsWhyEntriesProps } from "../../../hooks/useLandingContentfulQuery"
import { SectionViewedTrack } from "../../../modules/segment"
import { Carousel } from "../../Carousel/Carousel"
import { CreatorsWhyCard } from "./CreatorsWhyCard"

import "./CreatorsWhy.css"

export type CreatorsWhyProps = {
  items: ContentfulCreatorsWhyEntriesProps
}

export const CreatorsWhy = (props: CreatorsWhyProps) => {
  const l = useFormatMessage()
  const [sectionIsVisibile, setSectionIsVisibile] = React.useState(false)
  const carouselRef = React.useRef<any>(null)

  return (
    <Container
      fluid
      className={TokenList.join([
        "why-container",
        sectionIsVisibile && "why-container--visible",
      ])}
    >
      <SectionTrack
        sectionViewed={SectionViewedTrack.CreatorsWhy}
        onInView={() => setSectionIsVisibile(true)}
      />
      <Title>
        <span>{l("component.creators_landing.why.title_hightlight")}</span>{" "}
        {l("component.creators_landing.why.title")}
      </Title>

      <div className="why-wrapper">
        {props.items.list.map((item, key) => (
          <CreatorsWhyCard item={item} key={key} />
        ))}
      </div>

      <Carousel
        loading={false}
        ref={carouselRef}
        items={props.items.list || []}
        component={CreatorsWhyCard}
        slidesPerView={1}
        className="creators-why__carousel"
        freeMode={true}
        loop={false}
        forceToAxis={true}
      />
    </Container>
  )
}
