import React, { useCallback } from "react"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import useTrackContext from "decentraland-gatsby/dist/context/Track/useTrackContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import Link from "decentraland-gatsby/dist/plugins/intl/Link"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Hero } from "decentraland-ui/dist/components/Hero/Hero"

import { SectionViewedTrack, SegmentEvent } from "../../../modules/segment"
import backgroundVideoStudios from "../../../videos/create/background_video_studios.mp4"
import Video from "../../Video/Video"

import "./CreatorsEarn.css"

const skills = [
  "3D MODELING",
  "CREATIVE DIRECTION",
  "LAND RENTAL",
  "LINKED WEARABLES",
  "VENUE RENTAL",
  "ADVERTISING",
  "EMOTE DESIGN",
  "ENTERTAINMENT",
  "PROGRAMMING",
  "WEARABLE DESIGN",
]

export const CreatorsEarn = () => {
  const l = useFormatMessage()
  const [sectionIsVisibile, setSectionIsVisibile] = React.useState(false)

  const track = useTrackContext()

  const handleClickBrowseCreators = useCallback(() => {
    track(SegmentEvent.BrowserCreators)
  }, [])

  const handleClickAreYouCreator = useCallback(() => {
    track(SegmentEvent.AreYouCreator)
  }, [])

  return (
    <>
      <div
        className={TokenList.join([
          "creators-earn",
          sectionIsVisibile && "creators-earn--visible",
        ])}
      >
        <SectionTrack
          sectionViewed={SectionViewedTrack.CreatorsEarn}
          onInView={() => setSectionIsVisibile(true)}
        />
        <Hero centered>
          <Hero.Header>
            {l("component.creators_landing.earn.title")}{" "}
            {l("component.creators_landing.earn.second_title")}
            <span>{l("component.creators_landing.earn.title_hightlight")}</span>
          </Hero.Header>
          <Hero.Description>
            {l("component.creators_landing.earn.subtitle")}
            <div className="creators-earn__skills-container">
              {skills.map((skill: string) => (
                <div
                  key={skill}
                  className={TokenList.join([
                    "creators-earn__skill",
                    `icon-${skill.replaceAll(" ", "-").toLowerCase()}`,
                  ])}
                >
                  {skill}
                </div>
              ))}
            </div>
          </Hero.Description>
          <Hero.Actions>
            <div className="creators-earn__browse-creator-container">
              {l("component.creators_landing.earn.browse_creator_label")}
              <Button
                as={Link}
                primary
                href={l("component.creators_landing.earn.browse_creator_url")}
                onClick={handleClickBrowseCreators}
              >
                {l("component.creators_landing.earn.browse_creator_button")}
              </Button>
            </div>
            <div className="creators-earn__are-you-creator-container">
              {l("component.creators_landing.earn.are_you_creator_label")}
              <Button
                as={Link}
                secondary
                href={l("component.creators_landing.earn.are_you_creator_url")}
                onClick={handleClickAreYouCreator}
              >
                {l("component.creators_landing.earn.are_you_creator_button")}
              </Button>
            </div>
          </Hero.Actions>
          <Hero.Content
            className={TokenList.join([
              "earn-content",
              "earn-content__background",
            ])}
          >
            <Video
              className="landscape"
              loop
              muted
              autoPlay
              playsInline={true}
              width={1352}
              height={534}
              source={backgroundVideoStudios}
            />
          </Hero.Content>
        </Hero>
      </div>
    </>
  )
}
