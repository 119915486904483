import React from "react"

import SubTitle from "decentraland-gatsby/dist/components/Text/SubTitle"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import { ContentfulCreatorsWhyEntryProps } from "../../../hooks/useLandingContentfulQuery"

import "./CreatorsWhyCard.css"

export type CreatorsWhyCardProps = {
  item: ContentfulCreatorsWhyEntryProps
}

export const CreatorsWhyCard = (props: CreatorsWhyCardProps) => {
  const { item } = props
  return (
    <div
      className={TokenList.join(["why-item", `why-item__${item.id}`])}
      key={item.id}
    >
      <div className="why-item__container">
        <div className="why-item__image-container">
          <img src={item.image.url} />
        </div>
        <div className="why-item__text">
          <Title>{item.title}</Title>
          <SubTitle>{item.description}</SubTitle>
        </div>
      </div>
    </div>
  )
}
