import React from "react"

import useFeatureFlagContext from "decentraland-gatsby/dist/context/FeatureFlag/useFeatureFlagContext"
import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import env from "decentraland-gatsby/dist/utils/env"
import { Hero } from "decentraland-ui/dist/components/Hero/Hero"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"
import { Mobile, NotMobile } from "decentraland-ui/dist/components/Media/Media"

import {
  isWebpSupported,
  useImageOptimization,
  useVideoOptimization,
} from "../../../hooks/contentful"
import { ContentfulCreatorsHeroEntryFieldsProps } from "../../../hooks/useLandingContentfulQuery"
import useTypingListEffect from "../../../hooks/useTypingListEffect"
import { FeatureFlag } from "../../../modules/ff"
import { SectionViewedTrack, SegmentEvent } from "../../../modules/segment"
import { DownloadButton } from "../../Buttons/DownloadButton"
import { Chevron } from "../../Icon/Chevron"
import Video from "../../Video/Video"

import "./CreatorsHero.css"

export type CreatorsHeroProps = {
  item: ContentfulCreatorsHeroEntryFieldsProps
}

export const CreatorsHero = (props: CreatorsHeroProps) => {
  const { item } = props

  const currentWord = useTypingListEffect(item.changingWord)

  const imageLandscapeOptimized = useImageOptimization(item.imageLandscape.url)
  const imagePortraitOptimized = useImageOptimization(item.imagePortrait.url)
  const videoLandscapeOptimized = useVideoOptimization(item.videoLandscape.url)
  const videoPortraitOptimized = useVideoOptimization(item.videoPortrait.url)

  const handleDownloadCTA = useTrackLinkContext()
  const [ff, { loaded: featureFlagsLoaded, loading: featureFlagsLoading }] =
    useFeatureFlagContext()

  const isLoadingFF = !featureFlagsLoaded || featureFlagsLoading
  const isDownloadCTA =
    !isLoadingFF && ff.flags[FeatureFlag.CreatorHubDownloadPage]
  const isSubscribeCTA =
    !isLoadingFF && !ff.flags[FeatureFlag.CreatorHubDownloadPage]
  return (
    <>
      <div className={TokenList.join(["creators-hero"])}>
        <Hero centered>
          <Hero.Header>
            {item.titleFirstLine}
            <br />
            <span>{currentWord}</span>
            <br />
            {item.titleLastLine}
          </Hero.Header>
          <Hero.Description>{item.subtitle}</Hero.Description>
          <Hero.Actions>
            {isLoadingFF && <Loader active size="large" />}
            {isDownloadCTA && (
              <DownloadButton
                href={env("CREATOR_HUB_DOWNLOAD_URL")!}
                onClick={handleDownloadCTA}
                place={SectionViewedTrack.CreatorsHero}
                label={"Download"}
                event={SegmentEvent.Download}
              />
            )}
            {isSubscribeCTA && (
              <iframe
                src="https://embeds.beehiiv.com/9cce264b-ec04-43e5-8a52-4de0488b05e1?slim=true"
                data-test-id="beehiiv-embed"
                height="52"
                frameBorder="0"
                scrolling="no"
              ></iframe>
            )}
          </Hero.Actions>
          <Hero.Content
            className={TokenList.join([
              "hero-content",
              "hero-content__background",
            ])}
          >
            <NotMobile>
              {item.videoLandscape && (
                <Video
                  className="landscape"
                  loop
                  muted
                  autoPlay
                  playsInline={true}
                  width={item.videoLandscape.width}
                  height={item.videoLandscape.height}
                  poster={
                    (isWebpSupported() && imageLandscapeOptimized.webp) ||
                    imageLandscapeOptimized.jpg ||
                    imageLandscapeOptimized.optimized
                  }
                  source={videoLandscapeOptimized || item.videoLandscape.url}
                  type={item.videoLandscape.mimeType}
                />
              )}
              {item.imageLandscape && (
                <div
                  className="hero-content__image-landscape"
                  style={{
                    backgroundImage: `url(${
                      (isWebpSupported() && imageLandscapeOptimized.webp) ||
                      imageLandscapeOptimized.jpg ||
                      imageLandscapeOptimized.optimized
                    })`,
                  }}
                />
              )}
            </NotMobile>
            <Mobile>
              {item.videoPortrait && (
                <Video
                  className="portrait"
                  loop
                  muted
                  autoPlay
                  playsInline={true}
                  width={item.videoPortrait.width}
                  height={item.videoPortrait.height}
                  poster={
                    (isWebpSupported() && imagePortraitOptimized.webp) ||
                    imagePortraitOptimized.jpg ||
                    imagePortraitOptimized.optimized
                  }
                  source={videoPortraitOptimized || item.videoPortrait.url}
                  type={item.videoPortrait.mimeType}
                />
              )}
              {item.imagePortrait && (
                <div
                  className="hero-content__image-portrait"
                  style={{
                    backgroundImage: `url(${
                      (isWebpSupported() && imagePortraitOptimized.webp) ||
                      imagePortraitOptimized.jpg ||
                      imagePortraitOptimized.optimized
                    })`,
                  }}
                />
              )}
            </Mobile>
          </Hero.Content>
        </Hero>
      </div>
      <div className="creators-hero__continue-content">
        <Chevron dark />
      </div>
    </>
  )
}
