import React from "react"

import {
  DownloadButtonLabelContainer,
  DownloadButtonStyled,
} from "./DownloadButton.styled"
import { DownloadButtonProps } from "./DownloadButton.types"

const DownloadButton = React.memo((props: DownloadButtonProps) => {
  const {
    href,
    onClick,
    label,
    subLabel,
    place,
    event,
    isFullWidth,
    startIcon,
    endIcon,
  } = props
  return (
    <DownloadButtonStyled
      variant="contained"
      data-place={place}
      data-event={event || "click"}
      href={href}
      onClick={onClick}
      fullWidth={isFullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      <DownloadButtonLabelContainer>
        {label}
        {subLabel && <span>{subLabel}</span>}
      </DownloadButtonLabelContainer>
    </DownloadButtonStyled>
  )
})

export { DownloadButton }
