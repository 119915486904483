import React from "react"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Container } from "decentraland-ui/dist/components/Container/Container"
import { useMobileMediaQuery } from "decentraland-ui/dist/components/Media/Media"

import { ContentfulCreatorsCreateEntriesProps } from "../../../hooks/useLandingContentfulQuery"
import { SectionViewedTrack } from "../../../modules/segment"
import { Carousel, IndicatorType } from "../../Carousel/Carousel"
import { CreatorsCreateCard } from "./CreatorsCreateCard"

import "./CreatorsCreate.css"

export type CreatorsCreateProps = {
  item: ContentfulCreatorsCreateEntriesProps
}

export const CreatorsCreate = (props: any) => {
  const l = useFormatMessage()
  const [sectionIsVisibile, setSectionIsVisibile] = React.useState(false)
  const carouselRef = React.useRef<any>(null)
  const isMobile = useMobileMediaQuery()

  return (
    <Container
      fluid
      className={TokenList.join([
        "create-container",
        sectionIsVisibile && "create-container--visible",
      ])}
    >
      <SectionTrack
        sectionViewed={SectionViewedTrack.CreatorsCreate}
        onInView={() => setSectionIsVisibile(true)}
      />
      <Title>
        {l("component.creators_landing.create.title")}
        <span>{l("component.creators_landing.create.title_hightlight")}</span>
        {l("component.creators_landing.create.title_second_part")}
      </Title>

      <Carousel
        loading={false}
        ref={carouselRef}
        items={props.items.list || []}
        component={CreatorsCreateCard}
        spaceBetween={isMobile ? 23 : 24}
        slidesPerView="auto"
        className="creators-create__carousel"
        centeredSlides
        loop={false}
        forceToAxis
        pagination
        indicatorsType={IndicatorType.Dash}
        navigation={!isMobile}
      />
    </Container>
  )
}
