import React, { useCallback, useState } from "react"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import useTrackContext from "decentraland-gatsby/dist/context/Track/useTrackContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import Link from "decentraland-gatsby/dist/plugins/intl/Link"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"

import { ContentfulCreatorsLearnEntriesProps } from "../../../hooks/useLandingContentfulQuery"
import { SectionViewedTrack, SegmentEvent } from "../../../modules/segment"
import { Carousel } from "../../Carousel/Carousel"
import { CreatorsLearnCard } from "./CreatorsLearnCard"

import "./CreatorsLearn.css"

export type CreatorsLearnProps = {
  items: ContentfulCreatorsLearnEntriesProps
}

export const CreatorsLearn = (props: CreatorsLearnProps) => {
  const { items } = props

  const l = useFormatMessage()
  const track = useTrackContext()

  const carouselRef = React.useRef<any>(null)
  const [isSectionInView, setIsSectionInView] = useState(false)

  const handleClickWatchMore = useCallback(() => {
    track(SegmentEvent.WatchMore)
  }, [])

  const handleClickYourTutorial = useCallback(() => {
    track(SegmentEvent.YourTutorial)
  }, [])

  return (
    <div
      className={TokenList.join([
        "creators-learn",
        isSectionInView && "creators-learn--visible",
      ])}
    >
      <SectionTrack
        sectionViewed={SectionViewedTrack.CreatorsLearn}
        onInView={() => setIsSectionInView(true)}
      />
      <Title>
        <span>{l("component.creators_landing.learn.title_hightlight")}</span>{" "}
        {l("component.creators_landing.learn.title")}
      </Title>
      <Carousel
        loading={false}
        ref={carouselRef}
        items={items.list || []}
        component={CreatorsLearnCard}
        className="creators-learn__carousel"
        loop
        forceToAxis
        slidesPerView="auto"
        spaceBetween={20}
        centerInsufficientSlides
      />
      <div className="creators-learn__extra-container">
        <div className="creators-learn__decentraland-youtube-container">
          {l("component.creators_landing.learn.watch_more")}
          <Button
            as={Link}
            primary
            href={l("component.creators_landing.learn.watch_more_target")}
            onClick={handleClickWatchMore}
          >
            {l("component.creators_landing.learn.watch_more_button")}
          </Button>
        </div>
        <div className="creators-learn__submit-container">
          {l("component.creators_landing.learn.your_tutorial")}
          <Button
            as={Link}
            secondary
            href={l("component.creators_landing.learn.your_tutorial_target")}
            onClick={handleClickYourTutorial}
          >
            {l("component.creators_landing.learn.your_tutorial_button")}
          </Button>
        </div>
      </div>
    </div>
  )
}
